import { createApp } from 'vue';
import { createI18n } from 'vue-i18n'
import App from './App.vue';
import axios from 'axios';
import './registerServiceWorker';

axios.defaults.baseURL = process.env.VUE_APP_API;
axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');

const messages = {
  en: {
    message: {
      hello: 'hello world'
    }
  },
  ru: {
    message: {
      hello: 'Таки здравствуйте'
    }
  }
}

const i18n = createI18n({
  locale: 'ru',
  /*    messages: {
          en,
          ru
          },*/
  messages,
  fallbackLocale: 'en'
})

const app = createApp(App);
app.use(i18n);
app.mount('#app');
