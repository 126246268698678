<template>
  <div class="hello">
    <h3>Login</h3>
    <form novalidate @submit.prevent="onSubmit">
    <input v-model="state.eMail" placeholder="eMail Address">
    <input v-model="state.password" placeholder="password">
      <button @click="login()" class="">Login</button>
    </form>
   <div>{{state.message}}</div>
  </div>
  <div>
    <h3>Inventories</h3>
    <button @click="getInventories()" class="">List inventories</button>
    <div v-for="inventory in state.inventories" :key="inventory.id">
      {{inventory.id}} {{inventory.productTitle}} {{inventory.location.description}} {{inventory.deviceId}}
    </div>
  </div>
  <div>
    <h3>Company</h3>
    <button @click="getCompany()" class="">Company</button>
    <div v-if="state.company!=null">
      {{state.company.name}}
    </div>
  </div>
  <div>
    <h3>Users</h3>
    <button @click="getUsers()" class="">List Users</button>
    <div v-for="user in state.users" :key="user.id">
      {{user.roles}} {{user.eMail}} {{user.firstName}} {{user.lastName}}
    </div>
  </div>
  <div>
    <h3>Locations</h3>
    <button @click="getLocations()" class="">List locations</button>
    <div v-for="location in state.locations" :key="location.id">
      {{location.description}} {{location.city}}
    </div>
  </div>

  <a href="./SI_2_13.bin" download>Download FW</a>
</template>

<script>
import {onMounted, reactive} from 'vue';

export default {
  name: 'Login',
  props: {
  },
  setup() {
    const axios = require('axios');

    const state = reactive({
      message : null,
      eMail: null,
      password : null,
      inventories : null,
      company : null,
      users : null,
      locations : null,
    })

    function login() {
      axios.post('/authentication/authenticateUser',{username: state.eMail, password: state.password})
        .then((response) => {
          state.message = response.data.token;
          localStorage.setItem('token', response.data.token);
          axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
      }).catch((error) => {
          state.message = error.message;
      });
    }

    function getInventories() {
      axios.get('/inventory/getViewInventory')
        .then((response) => {
          state.inventories = response.data;
        }).catch((error) => {
          state.message = error.message;
      });
    }

    function getCompany() {
      axios.get('/company/getCompany')
        .then((response) => {
          state.company = response.data;
        }).catch((error) => {
        state.message = error.message;
      });
    }

    function getUsers() {
      axios.get('/user/listByCompanyId/'+state.company.id)
        .then((response) => {
          state.users = response.data;
        }).catch((error) => {
          state.message = error.message;
      });
    }

    function getLocations() {
      axios.get('/company/getLocations')
        .then((response) => {
          state.locations = response.data;
        }).catch((error) => {
          state.message = error.message;
      });
    }

    onMounted(() => {
      //fetchData();
    });

    return {
      login,
      state,
      getInventories,
      getCompany,
      getUsers,
      getLocations,
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
